.switch{
    position: relative;
    display: inline-block;
    width: 36px;
    height: 20px;
    padding: 4px 0;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider{
    position:absolute;
    cursor: pointer;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-color: #ccc;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: #fff;
    transition: .4s;
}

input:checked + .slider{
    background-color: #38B449;
}

input:checked + .slider:before{
    transform: translateX(16px);
}

.slider.rounded {
    border-radius: 34px;
}

.slider.rounded:before{
    border-radius: 50%;
}

