

.input{
    display: none;
}

.label{
   display: inline-flex;
   flex: 1 1 calc(33% - 42px);
   flex-wrap: wrap;
}

.custom-input{
    display: flex;
    align-items: center;
    padding: 18px 16px;
  
    background: #FAFAFA;
    color: #0000008F;
    border-radius: 8px;
    font-size: 16px;
    transition: .5s;
    user-select: none;
    overflow: hidden;
    cursor: pointer;
    width: 100%;
}

.custom-input:hover{
    background-color: #2B45D414;
}

.custom-input p {
    padding: 0;
    font-weight: 600;
}

.input:checked ~span {
    background-color: #2B45D4;
}

.containerSVG {
    display: flex;
    align-items: center;
    margin-right: 16px;
}

.input:checked ~span p {
    color:white;
    font-weight: 600;
}
.input:checked ~span .image {
    fill: white;
}

.input-texto{
    padding-bottom: 24px;
}
