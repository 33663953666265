.App {
    text-align: center;
}

.Embed-container {
  height: 80%;
  width: 100%;
  border-width: 0px;
  border-color: transparent;
}

iframe {
  border-width: 0px !important;
  border-style: inset !important;
  border-color: #ffffff !important;
  border-image: initial;
}